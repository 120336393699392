@import '../../../sass/variables';

.cta-block {
    .inner-content {
        margin-bottom: 40px;
    }

    .cta-buttons {
        margin-bottom: -15px;
    }

    &.cta-block-dark {
        background-color: $proDarkBlue;
        color: $white;

        h1 {
            color: $white;
        }

        a {
            &:not(.btn) {
                color: $linkColorOnDark;
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .cta-block {
        .cta-buttons {
            .btn {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
